<template>
  <div>
    <ErrorPage v-if="plan === 'basic'" />
    <div v-else>
    <h1 v-if="!canSetup" class="text-flame font-extrabold text-xl my-4">You do not have permission to setup this action</h1>
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="px-5">
      <div class="mt-8 font-semibold flex justify-start items-start">
        <p class="font-semibold text-base" style="width: 67%">
          Set Standard Probation Period (Period of time after which new
          employees should be confirmed)
        </p>
        <div
          class="flex justify-start items-center gap-3 -mt-3"
          style="width: 32%"
        >
          <c-select
            class="w-24"
            placeholder="--Select--"
            :options="numbers"
            v-model="settings.probationPeriod"
          />
          <p class="font-semibold text-base">months</p>
        </div>
      </div>
      <div>
        <div class="mt-5 flex font-semibold">
          Do you want to be able to confirm exceptionally?
          <Icon
            icon-name="icon-info"
            size="xsm"
            class="w-8 h-8 ml-4 -mt-1"
            v-tooltip.right="
              `Probation exception allows you confirm an employee outside of the generally set probation period.`
            "
          />
        </div>
        <RadioButton
          v-model="settings.exception"
          col-span="col-span-2"
          class="ml-3 mt-3"
          :options="[
            { name: 'Yes', radioName: 'exception', value: true },
            { name: 'No', radioName: 'exception', value: false },
          ]"
        />
      </div>
      <Divider border-dash class="w-full my-5" />
      <div class="mt-8 font-semibold flex justify-start items-start">
        <p class="font-semibold text-base" style="width: 67%">
          When an employee is recommended for an extension, extend probation by
        </p>
        <div
          class="flex justify-start items-center gap-3 -mt-3"
          style="width: 32%"
        >
          <c-select
            class="w-24"
            placeholder="--Select--"
            :options="numbers"
            v-model="settings.defaultExtendPeriod"
          />
          <p class="font-semibold text-base">months</p>
        </div>
      </div>
      <Divider border-dash class="w-full my-5" />
      <div class="flex flex-col justify-start gap-5">
        <div class="tedxt-darkPurple flex justify-start items-center gap-3">
          <h3 class="font-bold text-lg leading-5">
            Confirmation Performance Rating
          </h3>
          <Icon icon-name="icon-info" size="xsm" class="w-8 h-8" />
        </div>
        <div
          class="
            flex flex-row
            justify-evenly
            items-center
            gap-5
            divide-x divide-romanSilver
          "
        >
          <div class="w-1/2 flex flex-col justify-between items-start gap-2">
            <h6 class="text-lg font-bold">Criteria</h6>
            <div class="w-full flex justify-between items-center">
              <h6 class="font-normal text-base text-jet leading-5">
                Set up weight criterias for all levels.
              </h6>
              <div>
                <CButton
                  @onClick="openCriteriaModal = true"
                  :label="settings.criteria.length > 0 ? 'Update' : 'Setup'"
                  class="button-class-primary"
                  style="padding: 10px 20px; font-weight: 600;"
                />
              </div>
            </div>
          </div>
          <div class="w-1/2 flex flex-col justify-start items-start gap-2 pl-5">
            <h6 class="text-lg font-bold">Rating</h6>
            <div class="w-full flex justify-between items-center">
              <h6 class="font-normal text-base text-jet leading-5">
                Set the lowest and highest ratings.
              </h6>
              <div>
                <CButton
                  @onClick="openRatingModal = true"
                  :label="settings.ratings.length > 0 ? 'Update' : 'Setup'"
                  style="padding: 10px 20px; font-weight: 600"
                  class="button-class-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider border-dash class="w-full my-5" />
      <div>
        <h6 class="text-lg font-bold">Confirmation Sequence</h6>
        <div class="mt-5 font-semibold">
          Should confirmation assessment be mandatory for employee confirmation?
        </div>
        <RadioButton
          v-model="settings.confirmationMandatory"
          col-span="col-span-2"
          class="ml-3 mt-3"
          :options="[
            { name: 'Yes', radioName: 'mandatory', value: true },
            { name: 'No', radioName: 'mandatory', value: false },
          ]"
        />
      </div>
      <div v-if="canSetup" class="pl-3 mt-52 mb-5 flex flex-row">
        <Button @click="handleSubmit" :class="`bg-dynamicBackBtn text-white`">
          Save Changes
        </Button>
        <Button class="text-darkPurple ml-4"> Revert Changes </Button>
      </div>
      <criteria
        :open-modal="openCriteriaModal"
        @close="openCriteriaModal = false"
        :criteria-settings="settings.criteria"
        @criteriaForm="settings.criteria = $event"
      />
      <rating
        :open-modal="openRatingModal"
        @close="openRatingModal = false"
        :rating-settings="settings.ratings"
        @ratingForm="settings.ratings = $event"
      />
      <exception
        :open-modal="openExceptionModal"
        @close="openExceptionModal = false"
        :exception="settings.exception"
        @addException="settings.exception = $event"
      />
    </div>
    </div>
  </div>
</template>

<script>
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CButton from "@scelloo/cloudenly-ui/src/components/button";
import ErrorPage from "@/modules/Admin/error404";
import Button from "@/components/Button";
import Divider from "@/components/divider";
import RadioButton from "@scelloo/cloudenly-ui/src/components/radio";

export default {
  name: "ConfirmationSettings",
  components: {
    CSelect,
    CButton,
    Button,
    Divider,
    RadioButton,
    ErrorPage,
    Criteria: () => import("./Criteria"),
    Rating: () => import("./Rating"),
    Exception: () => import("./Exception"),
  },
  data() {
    return {
      openCriteriaModal: false,
      openRatingModal: false,
      openExceptionModal: false,
      settings: {
        probationPeriod: null,
        defaultExtendPeriod: null,
        confirmationMandatory: false,
        exception: null,
        ratings: [],
        criteria: [],
      },
      loading: false,
      numbers: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
        { id: 6, name: 6 },
        { id: 7, name: 7 },
        { id: 8, name: 8 },
        { id: 9, name: 9 },
        { id: 10, name: 10 },
        { id: 11, name: 11 },
        { id: 12, name: 12 },
      ],
      canSetup: true
    };
  },
  computed: {
    plan() {
      const result = this.$store.state.subscription ? this.$store.state.subscription.plan : null
      return result
    },
  },
  methods: {
    handleSubmit() {
      this.settings.userId = this.$AuthUser.id;
      this.settings.orgId = this.$orgId;
      this.$_saveConfirmationSetting(this.settings)
        .then(() => {
          this.$toasted.success("Settings saved successfully", {
            duration: 6000,
          });
          this.getSettings();
        })
        .catch((err) => {
          this.$toasted.error("Settings not saved confirm input", {
            duration: 5000,
          });
          throw new Error(err);
        });
    },
    getSettings() {
      this.loading = true;
      this.$_getConfirmationSettings()
        .then((response) => {
          const {
            confirmationMandatory,
            criteria,
            defaultExtendPeriod,
            probationPeriod,
            ratings,
            exception,
          } = response.data.confirmationProbationRating;
          this.settings = {
            confirmationMandatory,
            criteria,
            defaultExtendPeriod,
            probationPeriod,
            ratings,
            exception,
          };
          this.loading = false;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
   async getCanSetup(){
      try {
        await this.$handlePrivilege(
          "employeeManagement",
          "setupProbation&ConfirmationSettings"
        );
        this.canSetup = true
      } catch (error) {
        this.canSetup = false
      }
    }
  },
  async mounted() {

    if (this.plan !== 'basic') {
      await this.$handlePrivilege(
        "employeeManagement",
        "accessEmployeeManagementSettings"
      );
      this.getSettings();
      this.getCanSetup()
      }

  },
};
</script>

<style  scoped>
.tooltip {
  width: 393px;
}

.button-class-primary {
  background-color: var(--dynamic-Background) !important;
}
</style>