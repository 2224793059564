<template>
  <div class="py-6 w-full px-3">
    <h1 class="text-xl text-left font-bold flex mb-0 -mt-2">
      Employee Management
    </h1>
    <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
    <div v-if="enabledView">
      <Card>
        <Tabs
          class="mt-2 mx-2"
          :tabs="tabsItems"
          :active-tab="activeTab"
          @currentTab="activeTab = $event"
          :border="true"
        />
      </Card>
      <div>
        <ConfirmationSettings v-if="activeTab === 'Probation & Confirmation'" />
        <Ess v-if="activeTab == 'ESS Actions' " />
      </div>
    </div>
    <div v-else>
      <error-page />
    </div>
  </div>
</template>

<script>
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import Card from "@/components/Card";
import ErrorPage from "@/modules/Admin/error403";
import ConfirmationSettings from "./ConfirmationSettings";

export default {
  name: "EmployeeManagementSettings",
  components: {
    Tabs,
    Card,
    ErrorPage,
    ConfirmationSettings,
    Ess: () => import("./ess"),
  },
  data() {
    return {
      activeTab: this.$route.query.managementType || "Probation & Confirmation",
      enabledView: true,
      tabsItems: ["Probation & Confirmation", "ESS Actions"]
    };
  },
  methods: {},
  watch: {
    $route: {
      handler() {
        if (this.$route.query.managementType === "Probation & Confirmation") {
          this.activeTab = "Probation & Confirmation";
        }
      },
      immediate: true
    }
  },
  async mounted() {
    try {
        await this.$handlePrivilege(
          "employeeManagement",
          "accessEmployeeManagementSettings"
        );
        this.enabledView = true
      } catch (error) {
        this.enabledView = false
      }
  }
};
</script>

<style>
.border__dashed {
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878e99;
}
</style>
